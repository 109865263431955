<template lang="pug">
  transition(name="slide-fade")
    .home
      loading(:active.sync="busy", :is-full-page="true")
      .widget
        .widget-header
          h1.title Conversion Reports
        .widget-body
          form.form(@submit.prevent="uploadReport()")
            .row
              .col-sm-4
                .form-group
                  label Upload conversion report
                  doc-list(:files="report", :single="true", :cb="onReportReady")
              .col-sm-1
                .form-group
                  label &nbsp;
                  div
                    b-button(type="submit", variant="primary", :disabled="!file") Upload
              .col-sm-2
                .form-group
                  label &nbsp;
                  div
                    a.btn.btn-secondary(href="/assets/misc/conversion_report_sample.xlsx", target="_blank")
                      i.la.la-download
                      | Sample report

            .row(v-if="progress")
              .col-sm-12
                .progress-wrapper
                  b-progress(:value="100" height="2rem" show-progress)
                    b-progress-bar(
                      :value="progressPercentage('complete')"
                      variant="success"
                    )
                      | {{ progress.complete }} completed
                    b-progress-bar(
                      :value="progressPercentage('error')"
                      variant="danger"
                    )
                      | {{ progress.error }} failed
                    b-progress-bar(
                      :value="progressPercentage('new')"
                      variant="secondary"
                    )
                      | {{ progress.new }} pending

          p.clearfix &nbsp;
          .ov-table-wrapper
            table.table.table-bordered
              thead
                tr
                  th ID
                  th File Name
                  th Date
                  th Status
                  th Error
                  th Actions
              tbody
                tr(v-for="r in reports")
                  td {{ r.id }}
                  td
                    table-editable-cell(:value="r.file_name", :on-save-target="(newName) => onSave({ ...r, file_name: newName })")
                  td {{ r.date }}
                  td
                    span.badge.badge-secondary(v-if="r.status === 'new'") New
                    span.badge.badge-primary(v-if="r.status === 'process'") Process
                    span.badge.badge-danger(v-if="r.status === 'error'") Error
                    span.badge.badge-success(v-if="r.status === 'done'") Done
                  td
                    .error-message {{ r.error_message }}
                  td.actions
                    .action-buttons
                      span.btn.btn-action-info(v-on:click="downloadReport(r.id)", v-b-tooltip.hover.bottom, title="Download report")
                        a.no-link.text-white(v-if="r.file_path", :href="r.file_path", target="_blank")
                          i.la.la-download
          paginate(:paginator="paginate", @update-page-size="loadData")
</template>

<style lang="scss" scoped>
.badge {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal;
}

.error-message {
  max-height: 200px;
  overflow: auto;
}

.progress-wrapper {
  margin: 1rem 0;
}
</style>

<script>
export default {
  name: 'ConversionReports',
  data() {
    return {
      busy: false,
      file: null,
      report: [], // To make doc-list work properly
      reports: [],
      progress: null,
      progressTimer: null,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
    };
  },
  methods: {
    async loadData() {
      this.busy = true;
      const params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
      };
      try {
        const resp = await this.$ovReq.get('conversion-report/getList', { params });
        this.reports = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(
          Math.ceil(this.paginate.total / this.paginate.limit),
          1,
        );
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async uploadReport() {
      if (this.busy) return;
      if (!this.file) return;

      this.busy = true;
      this.stopProgressTracking();

      try {
        await this.$ovReq.post('conversion-report/upload', {
          path: this.file.full_path,
          name: this.file.name,
        });

        this.file = null;
        await this.loadData();
        this.$ovNotify.success('Report uploaded successfully');

        // Start tracking progress for the new report
        const lastReport = this.reports[0];
        if (lastReport) {
          this.startProgressTracking(lastReport.id);
        }
      } catch (e) {
        console.error(e);
      }

      this.busy = false;
    },

    async onSave(report) {
      this.busy = true;
      try {
        await this.$ovReq.post('conversion-report/save', {
          id: report.id,
          file_name: report.file_name,
        });
        this.$ovNotify.success('Report updated successfully');
        await this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    onReportReady(file) {
      this.file = file;
    },

    async checkProgress(reportId) {
      try {
        const data = await this.$ovReq.get('conversion-report/getProgress', {
          params: { report_id: reportId },
        });
        this.progress = data;

        // Stop tracking if all postbacks are processed
        if (data.new === 0) {
          this.stopProgressTracking();
          await this.loadData(); // Refresh the list to get final statuses
        }
      } catch (e) {
        console.error(e);
        this.stopProgressTracking();
      }
    },

    startProgressTracking(reportId) {
      this.stopProgressTracking();
      this.progressTimer = setInterval(() => {
        this.checkProgress(reportId);
      }, 5000);
      // Check immediately
      this.checkProgress(reportId);
    },

    stopProgressTracking() {
      if (this.progressTimer) {
        clearInterval(this.progressTimer);
        this.progressTimer = null;
      }
      this.progress = null;
    },

    progressPercentage(status) {
      if (!this.progress || !this.progress.total) return 0;
      return (this.progress[status] / this.progress.total) * 100;
    },
  },
  beforeDestroy() {
    this.stopProgressTracking();
  },
  async mounted() {
    await this.loadData();
  },
};
</script>